import { Component, OnInit } from '@angular/core';
import { SeguridadService } from "src/app/services/bi/seguridad/seguridad.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { DatePipe } from "@angular/common";
import { CommontoastrService } from "src/app/shared/toastr/commontoastr.service";

@Component({
  selector: 'app-ediguser',
  templateUrl: './ediguser.component.html',
  styleUrls: ['./ediguser.component.scss',
    "../../../../../assets/icon/icofont/css/icofont.scss"],
  providers: [DatePipe]
})
export class EdiguserComponent implements OnInit {

  listaEdigUser: any = [];
  listaBusquedaGrid: any = [];
  buscar: any = "";
  editar: any = 0;
  agregar: any = 0;
  itemSeleccionado: boolean = true;
  rowSeleccionado: any = {};

  userName: any = ""
  email: any = ""
  password: any = ""
  confirmPassword: any = ""
  estatus: any = 1
  allowEmails: any = 0

  constructor(private seguridadService: SeguridadService,
    private spinnerService: Ng4LoadingSpinnerService,
    private toastr: CommontoastrService,
    private datePipe: DatePipe) {
    this.cargaInicial();
  }

  ngOnInit() {
    this.userName = ""
    this.email = ""
    this.password = ""
    this.confirmPassword = ""
    this.estatus = 1
    this.allowEmails = 0
    console.log(this.email);
  }

  cargaInicial() {
    this.buscar = ""
    this.spinnerService.show();
    this.seguridadService.ObtenerEdigUserLista({}).subscribe(data => {
      this.spinnerService.hide();
      this.listaEdigUser = data;
      this.listaBusquedaGrid = data;
      //console.log(data);
    },
      error => {
        console.log(error)
      })
  }

  reaload() {
    this.cargaInicial();
    this.toastr.Success("Registros actualizados")
  }

  detalle(row) {
    this.itemSeleccionado = false;
    this.rowSeleccionado = row;
    console.log(row)
    console.log(this.rowSeleccionado['objectId']);
  }

  delete() {
    var body = {
      ObjectId: this.rowSeleccionado['objectId']
    }

    console.log(body)
    this.spinnerService.show()
    this.seguridadService.DeleteEdigUser(body).subscribe(data => {
      this.cargaInicial();
      this.toastr.Success("Usuario Eliminado con Exito")
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
    // console.log(body)
  }

  guardarCambiosUser() {
    var body = this.rowSeleccionado;

    body.allowReceiveMail = parseInt(body.allowReceiveMail);

    if (body.allowReceiveMail) {
      body.allowReceiveMail = 1
    }
    else {
      body.allowReceiveMail = 0
    }


    this.spinnerService.show();
    console.log(body)
    this.seguridadService.GuardarCambiosEdigUser(body).subscribe(data => {
      this.spinnerService.hide();
      this.editar = 0;
      console.log(data)
      this.toastr.Success("Cambios Guardados")
    },
      error => {
        console.log(error)
        this.toastr.Error("No se han guardado los cambios")
      })
    if (body.allowReceiveMail) body.allowReceiveMail = true
    else body.allowReceiveMail = false
  }

  guardarNewUser() {

    if (this.password == this.confirmPassword) {

      var body = {
        Description: this.userName,
        Email: this.email,
        StatusObjectId: this.estatus,
        AllowReceiveMail: this.allowEmails,
        CreatedDate: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
        CreatedByUserObjectId: 1,
        ModifiedByUserObjectId: 1,
        ModifiedDate: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
        AuthMode: 1
      }

      // parseInt(this.allowEmails);

      this.spinnerService.show();

      this.seguridadService.GuardarNuevoUsuario(body).subscribe(data => {

        this.spinnerService.hide();
        this.cargaInicial();

        this.agregar = 0;
        this.password = ""
        this.userName = ""
        this.email = ""
        this.estatus = 1
        this.allowEmails = 0
        this.toastr.Success("Nuevo Usuario Agregado");

      },
        error => {
          // console.log(error);
          this.spinnerService.hide();
          this.toastr.Error("Error")
        });

    } else {
      this.toastr.Error("Las contraseñas no coinciden")
    }

  }

  Buscar() {

    this.listaBusquedaGrid = []
    for (let i = 0; i < this.listaEdigUser.length; i++) {

      for (let x = 0; x < Object.keys(this.listaEdigUser[0]).length; x++) {
        var propiedades = Object.keys(this.listaEdigUser[i])
        var propiedad = propiedades[x]
        var valor = String(this.listaEdigUser[i][propiedad]).toLocaleUpperCase()

        if (valor.indexOf(this.buscar.toLocaleUpperCase()) != -1) {
          this.listaBusquedaGrid.push(this.listaEdigUser[i])
          break
        }

      }
    }

  }
  cancelar() {
    this.agregar = 0
    this.password = ""
    this.userName = ""
    this.email = ""
    this.estatus = 1
    this.allowEmails = 0
    this.editar = 0;
    this.confirmPassword = ""
  }

  add() {
    this.agregar = 1
    this.password = ""
    this.userName = ""
    this.email = ""
    this.estatus = 1
    this.allowEmails = 0
    this.confirmPassword = ""
  }


}
