import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/shared/resource.service';


@Injectable({
    providedIn: 'root'
})
export class CodigoBarrasService {
    public FromShareService: any = {};

    constructor(private api: ResourceService, private router: Router) { }

    //--------() Api gateway)--------------------//

    Get(data: any) {
        return this.api.get('payments/payments', data);
    }

    CreateCodigoBarras(data) {
        return this.api.post('payments/EquivalenciaCodigoBarrasGenericos', data)
    }

    GetCodigoBarrasLista(data) {
        return this.api.get('payments/EquivalenciaCodigoBarrasGenericos/list', data)
    }

    DeleteCodigoBarras(id) {
        return this.api.delete('payments/EquivalenciaCodigoBarrasGenericos', id)
    }

    UpdateCodigoBarras(data) {
        return this.api.put('payments/EquivalenciaCodigoBarrasGenericos', data)
    }

}
