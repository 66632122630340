import { Component, OnInit } from '@angular/core';
import { CodigoBarrasService } from "src/app/services/bi/catalogos/codigoBarras.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { DatePipe } from "@angular/common";
import { CommontoastrService } from "src/app/shared/toastr/commontoastr.service";

@Component({
  selector: 'app-EquivalenciaCodigoBarrasGenericos',
  templateUrl: './EquivalenciaCodigoBarrasGenericos.component.html',
  styleUrls: ['./EquivalenciaCodigoBarrasGenericos.component.scss',
    "../../../../../assets/icon/icofont/css/icofont.scss"]
})
export class EquivalenciaCodigoBarrasGenericosComponent implements OnInit {

  listaCodigoBarras: any = [];
  listaBusquedaGrid: any = []
  listaGrid: any = []
  buscar: any = "";
  editar: any = 0;
  agregar: any = 0;

  CodigoBarrasGenerico: any = "";
  CodigoBarrasHerdez: any = "";
  ObjectId: any = 0;

  itemSeleccionado: boolean = true;
  rowSeleccionado: any = {};

  constructor(private codigoBarrasService: CodigoBarrasService, private toastr: CommontoastrService,
    private spinnerService: Ng4LoadingSpinnerService,) {
    this.cargaInicial();
  }

  ngOnInit() {
  }

  cargaInicial() {
    this.buscar = ""
    this.spinnerService.show();
    this.codigoBarrasService.GetCodigoBarrasLista({}).subscribe(data => {
      console.log("datos cargados");
      this.listaCodigoBarras = data;
      this.listaBusquedaGrid = data;
      this.spinnerService.hide();
      console.log(data);
    },
      error => {
        console.log(error)
      })

  }

  UpdateCodigoBarras() {
    var body = {
      ObjectId: this.rowSeleccionado.objectId,
      CodigoBarrasGenerico: this.CodigoBarrasGenerico,
      CodigoBarrasHerdez: this.CodigoBarrasHerdez,
    }
    console.log(body)
    this.codigoBarrasService.UpdateCodigoBarras(body).subscribe(data => {
      this.spinnerService.hide();
      this.editar = 0;
      this.cargaInicial();
      this.cancelar();
      this.toastr.Success("Cambios Guardados")
    },
      error => {
        console.log(error)
        this.spinnerService.hide();
      })
  }

  CreateCodigoBarras() {
    this.spinnerService.show()
    var body = {
      CodigoBarrasGenerico: this.CodigoBarrasGenerico,
      CodigoBarrasHerdez: this.CodigoBarrasHerdez,
    }
    console.log("CreateCodigoBarras");
    console.log(body)
    this.codigoBarrasService.CreateCodigoBarras(body).subscribe(data => {
      this.cargaInicial();
      console.log(data);
      this.editar = 0;
      this.cancelar();
      this.toastr.Success("Agregado Correctamente!")
    },
      error => {
        console.log(error)
        this.spinnerService.hide();
      })

  }

  DeleteCodigoBarras() {
    // console.log(body)
    this.codigoBarrasService.DeleteCodigoBarras(this.rowSeleccionado.objectId).subscribe(data => {
      this.cargaInicial();
      console.log(data)
      this.toastr.Success("Eliminado Correctamente!")
    },
      error => {
        console.log(error)
        this.spinnerService.hide()
      })
    // console.log(body)
  }

  detalle(row) {
    this.itemSeleccionado = false;
    this.rowSeleccionado = row;
    this.ObjectId = row.objectId;
    this.CodigoBarrasGenerico = row.codigoBarrasGenerico;
    this.CodigoBarrasHerdez = row.codigoBarrasHerdez;
  }

  reaload() {
    this.cargaInicial();
    this.toastr.Success('Registros actualizados')
  }

  Buscar() {

    this.listaBusquedaGrid = []
    for (let i = 0; i < this.listaCodigoBarras.length; i++) {

      for (let x = 0; x < Object.keys(this.listaCodigoBarras[0]).length; x++) {
        var propiedades = Object.keys(this.listaCodigoBarras[i])
        var propiedad = propiedades[x]
        var prop = Object.keys(this.listaCodigoBarras[i][propiedad])
        var valor = String(this.listaCodigoBarras[i][propiedad].name).toLocaleUpperCase()
        if (valor.indexOf(this.buscar.toLocaleUpperCase()) != -1) {
          this.listaBusquedaGrid.push(this.listaCodigoBarras[i])
          break
        }
        valor = String(this.listaCodigoBarras[i][propiedad].aplicacion).toLocaleUpperCase()
        if (valor.indexOf(this.buscar.toLocaleUpperCase()) != -1) {
          this.listaBusquedaGrid.push(this.listaCodigoBarras[i])
          break
        }
        valor = String(this.listaCodigoBarras[i][propiedad].apartado).toLocaleUpperCase()
        if (valor.indexOf(this.buscar.toLocaleUpperCase()) != -1) {
          this.listaBusquedaGrid.push(this.listaCodigoBarras[i])
          break
        }
        valor = String(this.listaCodigoBarras[i][propiedad].contenidoDelCampo).toLocaleUpperCase()
        if (valor.indexOf(this.buscar.toLocaleUpperCase()) != -1) {
          this.listaBusquedaGrid.push(this.listaCodigoBarras[i])
          break
        }

      }
    }

  }

  cancelar() {
    this.editar = 0
    this.ObjectId = 0;
    this.CodigoBarrasHerdez = "";
    this.CodigoBarrasGenerico = "";
  }

}
