// export const environment = {
//   apiUrl: 'https://10.135.1.42:5050/api',
//   apiUrlSecurity: 'https://10.135.1.42:5050/api/security',
//   apiUrlEdigOrders: 'https://10.135.1.42:5050/api/ediorders',
//   production: false,
// };

export const environment = {
  apiUrl: 'https://apigetway.edig.dev.cloudherdez.com/api',
  apiUrlSecurity: 'https://apigetway.edig.dev.cloudherdez.com/api/security',
  // apiUrlSecurity: 'https://security.edig.dev.cloudherdez.com/api', http://inst-edig02:447
  apiUrlEdigOrders: 'https://apigetway.edig.dev.cloudherdez.com/api/ediorders',
  production: false,
};

// export const environment = {
//   apiUrl: 'https://localhost:7205/api',
//   apiUrlSecurity: 'https://localhost:7205/api/security',
//   apiUrlEdigOrders: 'https://localhost:7205/api/ediorders',
//   production: false,
// };
