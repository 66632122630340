import { Injectable } from '@angular/core';
import {ToastData, ToastOptions, ToastyService, ToastyConfig} from 'ng2-toasty';

@Injectable({
  providedIn: 'root'
})
export class CommontoastrService {
  titleSuccess: string= "Éxito";
  titleInfo: string = "Información";
  titleError: string = "Error";
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  timeout : number = 3000;


  constructor(  private toastyService: ToastyService, private toastyConfig: ToastyConfig) {
    this.toastyConfig.position = "top-right";
    this.toastyConfig.limit=1;
  }

  Success(Mensaje :string) {
    this.toastyService.clearAll();
    const toastOptions: ToastOptions = {
      title: this.titleSuccess,
      msg: Mensaje,
      showClose: this.showClose,
      timeout: this.timeout,
      theme: this.theme
    };

    this.toastyService.success(toastOptions);

  }

  Info(Mensaje :string) {

    const toastOptions: ToastOptions = {
      title: this.titleInfo,
      msg: Mensaje,
      showClose: this.showClose,
      timeout: this.timeout,
      theme: this.theme,
    };

    this.toastyService.info(toastOptions);

  }

  Error(Mensaje :string) {

    const toastOptions: ToastOptions = {
      title: this.titleError,
      msg: Mensaje,
      showClose: this.showClose,
      timeout: this.timeout,
      theme: this.theme,
    };

    this.toastyService.error(toastOptions);

  }


  Warning(Mensaje :string) {

    const toastOptions: ToastOptions = {
      title: this.titleInfo,
      msg: Mensaje,
      showClose: this.showClose,
      timeout: this.timeout,
      theme: this.theme,
    };

    this.toastyService.warning(toastOptions);

  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    const toastOptions: ToastOptions = {

      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }


}
