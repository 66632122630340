import { AuthService } from './../../../../services/self/seguridad/auth.service';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { NotificacionService } from './../../../../services/bi/operacion/notificaciones.service';
import { Router } from '@angular/router';
import { CommontoastrService } from 'src/app/shared/toastr/commontoastr.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { _ } from 'underscore'
import { ifError } from 'assert';
import { ScreenActionsService } from 'src/app/services/self/seguridad/actions.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss', '../../../../../assets/icon/icofont/css/icofont.scss'],
  encapsulation: ViewEncapsulation.None
})

export class NotificacionesComponent implements OnInit {
  public Page: any = {};
  public Screen: any = {};

  //Manejo de las notificaciones y Grid
  Filter: any = {};
  InstanciaNotificacion: any = {};
  ListaNotificaciones: any = [];
  ListaFiltrosNotificaciones: any = [];
  TrackAcciones: any = [];
  //Manejo Notificacion y Pop UP
  Headers: any = [];
  EmailTipoLista: any = [];
  ListaDataEmail: any = [];
  SingleRow: any = [];
  ListaAcciones: any = [];
  ListaClientes: any = [];
  ListaClientesFilter: any = [];
  ListaClientesAcceso: any = [];
  //Otros
  General: any = {};
  Auth: any = {};
  Task: any = {};
  timeouts: any;
  Opciones: any = [];
  Filtro: any = [];
  ///Cambio
  ListaDetalleCorreo: any = [];
  EmailDetail: any = {};
  EmailDetailFiltered: any = {};
  //html
  htmlString: any = "";
  html: any = "";

  constructor(private NotificacionService: NotificacionService, private router: Router,
    private toastr: CommontoastrService, private AuthService: AuthService,
    private spinnerService: Ng4LoadingSpinnerService, private ScreenActionsService: ScreenActionsService) {
    this.Page.AllowView = false;
    this.Page.AllowAdd = false;
    this.Page.AllowEdit = false;
    this.Page.Controller = this.router.routerState.snapshot.url.toString().substr(this.router.routerState.snapshot.url.lastIndexOf('/') + 1, this.router.routerState.snapshot.url.toString().length - 1);
    this.AuthService.IsAlive();
    this.Filter.NotificationTypeObjectId = [];
    this.General.Accion = 0;
    this.General.ValidaComentario = true;
    this.General.Comentario = true;
    this.General.DetalleComentario = "";
    this.General.SolicitaComentario = false;
    this.General.Data = {};
    this.General.Data.IdStatusTo = 0;
    this.General.Data.PositionColumnKey = 0;
    this.General.Track = {};
    this.Task.ShowList = true;
    this.Task.ShowTrack = false;
    this.Task.ShowEmailDetail = false;
    //Adicionales
    this.General.AllowSelect = false;
    this.InstanciaNotificacion.IsList = false;
    this.InstanciaNotificacion.IsTable = false;
    this.InstanciaNotificacion.CssClass = "";
    this.General.SelectAll = false;
    this.General.ShowAlert = false;
  }

  ngOnInit() {
    var data = localStorage.getItem('auth');
    if (data != undefined && data != null) {
      this.Auth = JSON.parse(data);
      this.General.IdUser = this.Auth.objectId;
    }
    this.spinnerService.show();

    //Validacion de Acceso a la Pagina
    this.ScreenActionsService.GetScreenActions(this.Page.Controller).then(data => {
      this.Screen.View = data.Get === undefined ? false : data.Get;;
      this.Screen.ViewAdd = data.Post === undefined ? false : data.Post;;
      this.Screen.ViewEdit = data.Put === undefined ? false : data.Put;;
      this.Screen.ViewDelete = data.Delete === undefined ? false : data.Delete;


      if (!this.Screen.View) {
        this.toastr.Error("Acceso denegado, no tiene permisos para ver el contenido.");
        setTimeout(() => {
          this.router.navigate(['default'])
        }, 3000);
      }
      else {
        this.CargarClientes(this.Auth.email);
        this.CargaDatos(this.Auth.email);
        this.CargaFiltros(this.Auth.email);
      }

    }).catch(error => {
      this.handleError(error);
    })
  }

  CargaDatos(id: any): any {
    this.NotificacionService.GetById(id).subscribe(data => {
      this.ListaNotificaciones = data

      if (this.ListaNotificaciones.length > 0) {
        this.General.ShowAlert = true;
        //this.ListaClientesFilter = _.countBy(this.ListaNotificaciones, "IdECPM");

      }
      //console.log( this.ListaNotificaciones);

      this.spinnerService.hide();
    },
      error => {
        this.ListaNotificaciones = [];
        this.handleError(error);
      });
  }

  CargaFiltros(id: any): any {

    this.NotificacionService.GetNotificationType(id).subscribe(data => {
      this.ListaFiltrosNotificaciones = data

    },
      error => {
        this.handleError(error);
      });
  }

  CargarClientes(Id: any) {
    this.NotificacionService.GetClients(Id).subscribe(data => {
      this.ListaClientes = data

    },
      error => {
        this.ListaNotificaciones = [];
        this.handleError(error);
      });
  }

  FiltraNotificacion() {
debugger
    if (this.Filter.JsonNotificacion !== undefined && this.Filter.JsonNotificacion !== null && this.Filter.JsonNotificacion !== "" && (this.Filter.DateIniCreated === undefined || this.Filter.DateEndCreated === undefined || this.Filter.DateIniCreated === "" || this.Filter.DateEndCreated === "")) {
      this.toastr.Info("Para busqueda avanzada, seleccione un rango de fechas");
    } else {
      this.spinnerService.show();

      this.Filter.Email = this.Auth.email;
      this.NotificacionService.Post(this.Filter).subscribe(data => {
        this.ListaNotificaciones = data;

        if (this.ListaNotificaciones.length == 0) {
          this.ListaNotificaciones.length = 0;
          this.toastr.Info("No se encontró información con los filtros proporcionados");
        }

        this.General.ShowAlert = false;
        this.spinnerService.hide();
      },
        error => {
          this.handleError(error);
          this.toastr.Error("Error de timeout.");
        });
    }
  }

  FilterClients(data: any) {
    this.ListaClientesFilter = data;
  }

  onPage(event) {
    clearTimeout(this.timeouts);
    this.timeouts = setTimeout(() => {
    }, 100);
  }

  //------------ Funciones Manejo Notificacion  -----------------
  GuardarAccion() {
    this.General.Data.IdNotification = 0;
    this.General.SelectedData = [];
    if (this.General.Accion > 0) {
      if (!this.General.SolicitaComentario || this.General.DetalleComentario.length == 0) {

        if (this.General.ValidaComentario) {
          this.toastr.Error("Debe introducir un comentario válido");
          return;
        }
      }

      this.General.Data.IdNotification = this.InstanciaNotificacion.ID;

      //-----------------------Validar la Seleccion de Registros---------------------------------

      if (this.General.Data.PositionColumnKey > 0) {
        let Seleccionados: any = [];
        let ListaSeleccionados = _.where(this.EmailDetailFiltered, { Activo: true });   //EmailDetail.List
        var NombreColumna = "Columna" + this.General.Data.PositionColumnKey;

        if (ListaSeleccionados.length == 0) {
          this.toastr.Error("Debe seleccionar al menos un registro");
          return;
        }

        //Validar Comentarios
        let validaComentario = 0;
        if (this.General.Options.InputTypeText) {

          try {
            ListaSeleccionados.forEach(function (item) {
              let Validacion: any = {};
              Validacion.Mensaje = "";
              if (item.Commentary === undefined || item.Commentary === "") {
                Validacion.Mensaje = "El campo de comentarios no puede estar vacío";
                throw Validacion;
              }
            });

          } catch (e) {
            this.toastr.Error(e.Mensaje);
            return false;
          }

        }

        this.spinnerService.show();

        ListaSeleccionados.forEach(it => {
          Seleccionados.push({
            "NotificationRegistryObjectId": this.General.Data.IdNotification,
            "NotificationActionObjectId": this.General.Accion,
            "NotificationRegistryDetailObjectId": parseInt(it.Columna0),
            "Data": it[NombreColumna],
            "Commentary": it.Commentary,
            "PercentageApproval": it.PercentageApproval
          });
        });
        this.General.SelectedData = Seleccionados;

      }

      //----------------------------------------------------------------------------------------
      this.NotificacionService.ChangeStatusNotification(this.General).subscribe(data => {
        var result = data.valueOf();

        if (result > 0) {

          this.ListaNotificaciones.forEach(it => {
            if (it.ObjectId === this.General.Data.IdNotification) {
              it.StatusName = this.General.Data.StatusName;
              it.StatusObjectId = this.General.Data.IdStatusTo;
              return;
            }
          });

          this.ListaNotificaciones = [...this.ListaNotificaciones]
          this.toastr.Success("La acción se registro correctamente");
          this.Regresar();
          this.spinnerService.hide();
        }
        else {
          this.toastr.Error("Ocurrió un error al guardar la información ");
          this.spinnerService.hide();
        }
      },
        error => {
          this.handleError(error);
        });
    }
    else
      this.toastr.Error("Debe Seleccionar una Acción ");
  }

  TrackNotificacion(item) {
    this.General.Track = {};
    this.General.Track.Titulo = item.Titulo;
    this.General.Track.Descripcion = item.NotificationDescription;
    this.General.Track.IdNotificacion = item.ObjectId;
    this.NotificacionService.GetTrackByIdNotification(item.ObjectId).subscribe(data => {
      this.TrackAcciones = data;
      if (this.TrackAcciones.length > 0)
        this.ShowTrack();
      else
        this.toastr.Warning("No hay acciones tomadas para esta notificación");

    },
      error => {
        this.handleError(error);
      });
  }

  Regresar() {
    this.ShowList();
  }

  //-------------Funciones PopUp y Acciones----------------------
  ConsultaDetalle(value) {
    this.ResetColor();
    this.ResetAccionNotificacion();

    if (value.StatusObjectId != 3) //Si no esta concluido entonces mostrar check (si aplica seleccion)
      this.General.AllowSelect = value.AllowSelect;
    let oAccion: any = {};
    oAccion.IdNotificacion = value.ObjectId;
    oAccion.ParentObjectId = value.ParentObjectId;
    oAccion.IdStatus = value.StatusObjectId;
    oAccion.Email = this.Auth.email;

    this.NotificacionService.GetDetailEmail(oAccion).subscribe(data => {
      this.EmailDetail = data;

      if (this.EmailDetail.Options != null && Object.keys(this.EmailDetail.Options).length > 0)
        this.General.Options = this.EmailDetail.Options;


      this.General.Data.PositionColumnKey = value.PositionColumnKey;


      if (this.EmailDetail.Type === "Lista") {
        this.InstanciaNotificacion = JSON.parse(this.EmailDetail.Detail);
        this.EmailTipoLista = this.InstanciaNotificacion.Configuracion[0].Data;
        this.InstanciaNotificacion.Titulo = value.Titulo;
        this.InstanciaNotificacion.ID = value.ObjectId;
        this.InstanciaNotificacion.IsList = true;

      }
      else {
        this.EmailDetailFiltered = JSON.parse(JSON.stringify(this.EmailDetail.List));
        this.InstanciaNotificacion.Titulo = value.Titulo;
        this.InstanciaNotificacion.ID = value.ObjectId;

        if (this.EmailDetail.Header) {
          this.Headers = this.EmailDetail.Header.split("|");
          this.InstanciaNotificacion.IsTable = true;
        }

      }
    },
      error => {
        this.ListaNotificaciones = [];
        this.handleError(error);
      });


    if (value.ParentObjectId == 1 || value.ParentObjectId == 2) {
      document.querySelector('#PopNotificacion').classList.add('md-show');
    }
    else {
      this.ShowEmailDetail();
      this.NotificacionService.GetActionByIdNotification(oAccion).subscribe(data => {
        this.ListaAcciones = data;
      },
        error => {
          this.handleError(error);
        });

    }

    value.CssStyle = "text-danger";
    //InstanciaNotificacion

  }

  FilterData(value) {
    this.EmailDetailFiltered = this.FilterApply(this.EmailDetail.List, value);
  }

  FilterApply(items: Array<any>, value: string): any {
    if (value) {
      return items.filter(item => {
        const filter = Object.keys(item);
        return filter.some(key => {
          if (key !== "Commentary" && key !== "PercentageApproval" && key !== "Activo" && key !== "InputNumber")
            return item[key].toLowerCase().indexOf(value.toLowerCase()) !== -1
        })
      });
    }
    else
      return items;

  }

  SeleccionActivos(evt, index) {
    this.EmailDetail.List[index].Activo = false;

    if (evt.target.checked)
      this.EmailDetail.List[index].Activo = true;

    //console.log(evt.target.checked);
    //console.log(index);
  }

  ResetAccionNotificacion() {
    this.General.Accion = 0;
    this.General.Comentario = true;
    this.General.DetalleComentario = "";
    this.General.SolicitaComentario = false;
    this.General.Track = {};
    this.General.Data = {};
    this.General.Data.IdStatusTo = 0;
    this.General.Data.PositionColumnKey = 0;

    this.General.ValidaComentario = true;
    this.ListaAcciones.length = 0;
    this.EmailTipoLista = [];

    this.InstanciaNotificacion = {};
    this.InstanciaNotificacion.IsList = false;
    this.InstanciaNotificacion.IsTable = false;
    this.General.SelectAll = false;
    this.General.AllowSelect = false;

    //Opciones
    this.General.Options = {};
    this.General.Options.InputTypeText = false;
    this.General.Options.InputTypeNumber = false;

  }

  splitDescription(theString: string) {
    this.SingleRow = theString.split('|');
    return this.SingleRow;
  }

  openMyModal(event) {
    document.querySelector('#' + event).classList.add('md-show');
  }

  closeMyModal(event) {
    ((event.target.parentElement.parentElement).parentElement).classList.remove('md-show');
  }

  onChange(IdAccion) {
    this.General.Comentario = true;
    this.General.Data.IdStatusTo = 0;
    this.General.Data.StatusName = "";

    let accion = _.find(this.ListaAcciones, function (item) { return item.ObjectId == parseInt(IdAccion); });
    if (accion.HasCommentary) {
      this.General.Comentario = false;
      this.General.SolicitaComentario = true;

    }
    else
      this.General.ValidaComentario = false;

    this.General.Data.IdStatusTo = accion.StatusTo;
    this.General.Data.StatusName = accion.StatusName;
  }

  ResetColor() {
    this.ListaNotificaciones.forEach(it => {
      it.CssStyle = "";
    });
  }

  CheckValue(item): void {
    try {
      if (item.InputNumber.toString().length > 3) {
        item.InputNumber = null;
        return;
      }

      if (item.InputNumber > 100) {
        item.InputNumber = 100;
        return;
      }
    }
    catch {

      item.InputNumber = 0;
    }
  }
  //Funciones de Paneles

  ShowList() {
    this.Task.ShowList = true;
    this.Task.ShowTrack = false;
    this.Task.ShowEmailDetail = false;
  }

  ShowTrack() {
    this.Task.ShowList = false;
    this.Task.ShowTrack = true;
    this.Task.ShowEmailDetail = false;
  }

  ShowEmailDetail() {
    this.Task.ShowList = false;
    this.Task.ShowTrack = false;
    this.Task.ShowEmailDetail = true;
  }

  SelectAllRows(value) {

    this.EmailDetailFiltered.forEach(it => {
      it.Activo = value;
    });

    this.EmailDetail.List.forEach(it => {
      it.Activo = value;
    });
  }

  //---------Funciones Manejo de Errores--------------------------
  private handleError(error: any) {
    this.spinnerService.hide();
    if (error.status == 401) {
      localStorage.removeItem('auth');
      this.router.navigate(['login'])
    }
    this.toastr.Error(error.error.Descripcion);
  };
}
